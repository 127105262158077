.slider .slide {
    padding: 0;
}

.child-element {
    opacity: .5;
    width: 100%;
    margin: 0 0;
    transition: all .5s;
    transform: scale(.85);
}

.slick-slide {
    width: 300px !important;
    position: relative;
    cursor: grabbing;
    top: 50%;
}

.slick-center .child-element {
    opacity: 1;
    margin: 0;
    height: 100%;
    transform: scale(1);
}

.child-element > img {
    aspect-ratio: 4/5;
    object-fit: cover;
    margin: auto;
    max-height: 400px;
    height: 400px;
}

.slick-track,
.slick-slide,
.slick-slide img {
    transform: translate3d(0, 0, 0);
}
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

