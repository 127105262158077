.square {
    width: 86px;
    height: 86px;
    font-size: 63px;
}

.colored {
    background-color: #07bc0c;
}

.b-b {
    border-bottom: black 1px solid;
}

.b-r {
    border-right: black 1px solid;
}

.b-l {
    border-left: black 1px solid;
}

.b-t {
    border-top: black 1px solid;
}

.board-row {
    height: 86px;
}

.board {
    display: grid;
    grid-template-rows: 86px 86px 86px;
    grid-template-columns: 86px 86px 86px;
    justify-self: center;
    justify-content: center;
    justify-items: center;
}