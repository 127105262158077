.timeline {
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
}

.timestamp {
    margin-bottom: 20px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.point {
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    border-top: 4px solid #1f2937;
    position: relative;
    transition: all 200ms;
}

.point span {
    font-weight: 600;
    padding-top: 20px;
}

.point.default span:before {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 25px;
    background-color: #1f2937;
    border: 4px solid #4b5563;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -17px;
}

.point.add span:before {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 25px;
    border: 4px solid #4b5563;
    position: absolute;
    right: 27px;
    top: -22px;
    cursor: pointer;
}

.point.add {
    cursor: pointer;
    width: 80px;
}

.point.add span {
    position: absolute;
    right: -65px;
}

.point-icon {
    position: absolute;
    right: -38px;
    top: -22px;
}

.swiper {
    overflow: auto;
    height: 100%;
}

.swiper-control {
    text-align: right;
}

.swiper-container {
    width: 100%;
    height: 250px;
    margin: 50px 0;
    overflow: hidden;
    padding: 0 20px 30px 20px;
}