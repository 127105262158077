.f3 {
    height: 350px;
    max-height: calc(100vh - 80px);
    width: 900px;
    max-width: 100%;
    margin: auto;
    position: relative;
}

.full {
    height: 80vh;
    max-height: unset;
    width: 900px;
    margin: auto;
    position: relative;
}

.f3 .cursor-pointer {
    cursor: pointer;
}

.f3 .link {
    stroke: #000000;
}

.f3 svg.main_svg {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: #394253;
}

.f3 svg.main_svg text {
    fill: currentColor;
}

.f3 rect.card-female,
.f3 .card-female .card-body-rect,
.f3 .card-female .text-overflow-mask {
    fill: white;
}

.f3 rect.card-male,
.f3 .card-male .card-body-rect,
.f3 .card-male .text-overflow-mask {
    fill: white;
}

.f3 .card tspan {
    font-size: 105%;
}

.f3 .card-genderless .card-body-rect,
.f3 .card-genderless .text-overflow-mask {
    fill: white;
}

.f3 .card_add .card-body-rect {
    fill: #394253;
    stroke-width: 4px;
    stroke: #fff;
    cursor: pointer;
}

.f3 .genderless-icon rect {
    fill: #394253;
}

.f3 g.card_add text {
    fill: #fff;
}

.f3 .card-main {
    stroke: #000;
}

.f3 .card_family_tree rect {
    transition: 0.1s;
}

.f3 .card_add_relative {
    cursor: pointer;
    color: black;
    transition: 0.3s;
}

.f3 .card_add_relative circle {
    fill: rgba(0, 0, 0, 0);
}

.f3 .card_add_relative:hover {
    color: black;
}

.f3 .card_edit.pencil_icon {
    color: black;
    transition: 0.3s;
}

.f3 .card_edit.pencil_icon:hover {
    color: black;
}

.f3 .card_break_link,
.f3 .link_upper,
.f3 .link_lower,
.f3 .link_particles {
    transform-origin: 50% 50%;
    transition: 0.3s;
}

.f3 .card_break_link {
    color: #fff;
}

.f3 .card_break_link.closed .link_upper {
    transform: translate(-140.5px, 656px);
}

.f3 .card_break_link.closed .link_upper g {
    transform: rotate(-58deg);
}

.f3 .card_break_link.closed .link_particles {
    transform: scale(0);
}

.f3 .input-field input {
    height: 2.5rem !important;
}

.f3 .input-field > label:not(.label-icon).active {
    -webkit-transform: translateY(-8px) scale(0.8);
    transform: translateY(-8px) scale(0.8);
}