@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .font-fragille {
        font-family: fragille;
    }
}

@layer components {
    .markdown > h1 {
        @apply font-fragille text-left md:text-left text-xl md:text-4xl text-black;
    }

    .markdown > h2 {
        @apply text-lg font-bold;
    }

    .markdown > h3 {
        @apply text-lg font-bold;
    }

    .markdown img {
        @apply rounded-xl;
    }

    .markdown a {
        @apply link link-neutral;
    }

}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0 0 50px !important;
}

.fullheight {
    height: 100vh;
}

@font-face {
    font-family: fragille;
    src: url("../assets/font/Fragille-Reguler.ttf");
}

.font-fragille {
    font-family: fragille;
}

/* BOUNCE ANIMATION */
.bounce-ani {
    animation: bounce 0.5s ease 0s 1 normal forwards;
}

@font-face {
    font-family: gilroy;
    src: url("../assets/font/Gilroy-Light.otf");
}

body {
    margin: 0;
    font-family: gilroy, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-fragille {
    font-family: fragille;
}

.font-gilroy {
    font-family: gilroy;
}

.wrapper {
    @apply w-[90%] md:w-[500px] mx-auto;
}

@keyframes bounce {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: translateY(-250px);
    }

    100% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateY(0);
    }
}

.shadow__style {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 70px 50px 0;
}

.triangle {
    border-color: #ffe1e1 transparent transparent transparent;
}

.triangle__white {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 70px;
    border-color: transparent #f8f8f8 transparent transparent;
}

.box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 3px;
    border: 3px solid black;
}

/* On mouse-over, add a grey background color */
.box:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.box input:checked ~ .checkmark {
    background-color: #000000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.box input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.box .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slick-slider {
    background-color: #ffffff !important;
}

.slider {
    background-color: #ccc;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 6px;
    bottom: 6px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #000000;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
} */

input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/*=================ANIMATION=================*/
.fade__ani {
    animation: fadeIn 2s ease 0s 1 normal forwards;
}

@keyframes fadeIn {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.top__fade__ani {
    animation: fadeTop 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0s 1 normal forwards;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.pointani {
    animation: pointani 1s infinite;
}

@keyframes pointani {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(8px);
    }
    100% {
        transform: translateX(0px);
    }
}

._is6ww {
    width: 100% !important;
}